import { UserInfo, ConversationRequest, Conversation, ChatMessage, CosmosDBHealth, CosmosDBStatus, APIConfig } from "./models";
import { chatHistorySampleData } from "../constants/chatHistory";
import conversationText from "./conversation.txt";
import systemmessageText from "./systemmessage.txt";
import uuid from "react-uuid";

type Message = { 
    role: string,
    content: string
}

export async function conversationApi(options: ConversationRequest, config: APIConfig): Promise<Response> {

    var formdata = new FormData();
    formdata.append("textinput", "1");
    formdata.append("lang", config.language);
    formdata.append("textoutput", config.replymode !== "voice" ? "1" : "0");
    formdata.append("voice", config.voice);
    formdata.append("maxresponse", config.maxresponse); //optional - default 800
    formdata.append("temperature", config.temperature); //optional - default 0.5
    formdata.append("frequencepenalty", config.frequencypenalty); //optional - default 0 
    formdata.append("presencepenalty", config.presencepenalty); //optional - default 0 
    formdata.append("phraselist", config.phraselist);
    formdata.append("debug", "0");
    formdata.append("custid", config.customerid);
    formdata.append("operation", "0");
    formdata.append("previousmessages", config.examples);
    formdata.append("message", options.messages[options.messages.length - 1].content);

    let sysmessage = config.systemmessage;
    if(config.customerdata.length > 0) sysmessage = config.systemmessage.replace("<<customerdata>>", config.customerdata);
    formdata.append("systemmessage", sysmessage); //console.log(sysmessage);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(config.url + "?code=" + config.accesscode, requestOptions as RequestInit); 

    if (!response.ok) {
        if(response.status == 404) {const str = await response.text(); throw new Error("Unable to fetch from AutoQM API: " + str + " please check your configuration.");}
        throw new Error("Unable to fetch from AutoQM API: " + response.statusText + " please check your configuration.");
    }

    return response;
}

export async function audioConversationApi(options: ConversationRequest, config: APIConfig): Promise<Response> {

    var formdata = new FormData();
    formdata.append("file", options.messages[options.messages.length - 1].blob as Blob, uuid() + ".webm");
    formdata.append("autodetect", "1");
    formdata.append("textinput", "0");
    formdata.append("lang", config.language);
    formdata.append("textoutput", config.replymode !== "text" ? "0" : "1");
    formdata.append("voice", config.voice);
    formdata.append("maxresponse", config.maxresponse); //optional - default 800
    formdata.append("temperature", config.temperature); //optional - default 0.5
    formdata.append("frequencepenalty", config.frequencypenalty); //optional - default 0 
    formdata.append("presencepenalty", config.presencepenalty); //optional - default 0 
    formdata.append("phraselist", config.phraselist);
    formdata.append("debug", "0");
    formdata.append("custid", config.customerid);
    formdata.append("operation", "0");
    formdata.append("previousmessages", config.examples);

    let sysmessage = config.systemmessage;
    if(config.customerdata.length > 0) sysmessage = config.systemmessage.replace("<<customerdata>>", config.customerdata);
    formdata.append("systemmessage", sysmessage); //console.log(sysmessage);
    
    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(config.url + "?code=" + config.accesscode, requestOptions as RequestInit); //"http://localhost:7071/api/Chat?code=j4uurVQbCe7aJnfwnSzkFv0pVjSQua3h", requestOptions as RequestInit)//
    if (!response.ok) {
        if(response.status == 404) {const str = await response.text(); throw new Error("Unable to fetch from AutoQM API: " + str + " please check your configuration.");}
        throw new Error("Unable to fetch from AutoQM API: " + response.statusText + " please check your configuration.");
    }

    //console.log(response);
    return response;
}

export async function transcriptionApi(options: ConversationRequest, filename: string, config: APIConfig): Promise<Response> {

    var formdata = new FormData();
    formdata.append("file", options.messages[options.messages.length - 1].blob as Blob, filename);
    formdata.append("autodetect", "1");
    formdata.append("lang", config.language);
    formdata.append("debug", "0");
    formdata.append("operation", "1");
    formdata.append("custid", config.customerid);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(config.url + "?code=" + config.accesscode, requestOptions as RequestInit); //"http://localhost:7071/api/Chat?code=j4uurVQbCe7aJnfwnSzkFv0pVjSQua3h", requestOptions as RequestInit)//
    if (!response.ok) {
        throw new Error("Unable to fetch from AutoQM API: " + response.statusText + " please check your configuration.");
    }

    //console.log(response);
    return response;
}

export async function autoQMApi(config: APIConfig): Promise<Response> {

    var formdata = new FormData();
    formdata.append("textinput", "1");
    formdata.append("lang", config.language);
    formdata.append("debug", "0");
    formdata.append("operation", "5");
    formdata.append("message", config.rawcustomerdata);
    formdata.append("custid", config.customerid);
    formdata.append("previousmessages", config.examples);
    formdata.append("basescore", config.basescore);
    formdata.append("questions", config.questions);
    formdata.append("aspects", config.aspects);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    const response = await fetch(config.url + "?code=" + config.accesscode, requestOptions as RequestInit); //"http://localhost:7071/api/Chat?code=j4uurVQbCe7aJnfwnSzkFv0pVjSQua3h", requestOptions as RequestInit)//
    if (!response.ok) {
        if(response.status == 404) {const str = await response.text(); throw new Error("Unable to fetch from AutoQM API: " + str + " please check your configuration.");}
        throw new Error("Unable to fetch from AutoQM API: " + response.statusText + " please check your configuration.");
    }

    //console.log(response);
    return response;
}

export async function getUserInfo(): Promise<UserInfo[]> {
    const response = require("./me.json");

    // await fetch('/.auth/me'); 
    // if (!response.ok) {
    //     console.log("No identity provider found. Access to chat will be blocked.")
    //     return [];
    // }

    // const payload = await response.json();
    // return payload;
    return response;
}

// export const fetchChatHistoryInit = async (): Promise<Conversation[] | null> => {
export const fetchChatHistoryInit = (): Conversation[] | null => {
    // Make initial API call here

    // return null;
    return chatHistorySampleData;
}

export const historyList = async (offset = 0): Promise<Conversation[] | null> => {
    const response = await fetch(`/history/list?offset=${offset}`, {
        method: "GET",
    }).then(async (res) => {
        const payload = await res.json();
        if (!Array.isArray(payload)) {
            console.error("There was an issue fetching your data.");
            return null;
        }
        const conversations: Conversation[] = await Promise.all(payload.map(async (conv: any) => {
            let convMessages: ChatMessage[] = [];
            convMessages = await historyRead(conv.id)
                .then((res) => {
                    return res
                })
                .catch((err) => {
                    console.error("error fetching messages: ", err)
                    return []
                })
            const conversation: Conversation = {
                id: conv.id,
                title: conv.title,
                date: conv.createdAt,
                messages: convMessages
            };
            return conversation;
        }));
        return conversations;
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    })

    return response
}

export const historyRead = async (convId: string): Promise<ChatMessage[]> => {
    const response = await fetch("/history/read", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then(async (res) => {
            if (!res) {
                return []
            }
            const payload = await res.json();
            let messages: ChatMessage[] = [];
            if (payload?.messages) {
                payload.messages.forEach((msg: any) => {
                    const message: ChatMessage = {
                        id: msg.id,
                        role: msg.role,
                        date: msg.createdAt,
                        content: msg.content,
                        feedback: msg.feedback ?? undefined
                    }
                    messages.push(message)
                });
            }
            return messages;
        }).catch((err) => {
            console.error("There was an issue fetching your data.");
            return []
        })
    return response
}

export const historyGenerate = async (options: ConversationRequest, abortSignal: AbortSignal, convId?: string): Promise<Response> => {
    let body;
    if (convId) {
        body = JSON.stringify({
            conversation_id: convId,
            messages: options.messages
        })
    } else {
        body = JSON.stringify({
            messages: options.messages
        })
    }
    const response = await fetch("/history/generate", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: body,
        signal: abortSignal
    }).then((res) => {
        return res
    })
        .catch((err) => {
            console.error("There was an issue fetching your data.");
            return new Response;
        })
    return response
}

export const historyUpdate = async (messages: ChatMessage[], convId: string): Promise<Response> => {
    const response = await fetch("/history/update", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            messages: messages
        }),
        headers: {
            "Content-Type": "application/json"
        },
    }).then(async (res) => {
        return res
    })
        .catch((err) => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            }
            return errRes;
        })
    return response
}

export const historyDelete = async (convId: string): Promise<Response> => {
    const response = await fetch("/history/delete", {
        method: "DELETE",
        body: JSON.stringify({
            conversation_id: convId,
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then((res) => {
            return res
        })
        .catch((err) => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            }
            return errRes;
        })
    return response;
}

export const historyDeleteAll = async (): Promise<Response> => {
    const response = await fetch("/history/delete_all", {
        method: "DELETE",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then((res) => {
            return res
        })
        .catch((err) => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            }
            return errRes;
        })
    return response;
}

export const historyClear = async (convId: string): Promise<Response> => {
    const response = await fetch("/history/clear", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then((res) => {
            return res
        })
        .catch((err) => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            }
            return errRes;
        })
    return response;
}

export const historyRename = async (convId: string, title: string): Promise<Response> => {
    const response = await fetch("/history/rename", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            title: title
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then((res) => {
            return res
        })
        .catch((err) => {
            console.error("There was an issue fetching your data.");
            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            }
            return errRes;
        })
    return response;
}

export const historyEnsure = async (): Promise<CosmosDBHealth> => {
    // const response = await fetch("/history/ensure", {
    //     method: "GET",
    // })
    // .then(async res => {
    //     let respJson = await res.json();
    //     let formattedResponse;
    //     if(respJson.message){
    //         formattedResponse = CosmosDBStatus.Working
    //     }else{
    //         if(res.status === 500){
    //             formattedResponse = CosmosDBStatus.NotWorking
    //         }else if(res.status === 401){
    //             formattedResponse = CosmosDBStatus.InvalidCredentials    
    //         }else if(res.status === 422){ 
    //             formattedResponse = respJson.error    
    //         }else{
    //             formattedResponse = CosmosDBStatus.NotConfigured
    //         }
    //     }
    //     if(!res.ok){
    //         return {
    //             cosmosDB: false,
    //             status: formattedResponse
    //         }
    //     }else{
    //         return {
    //             cosmosDB: true,
    //             status: formattedResponse
    //         }
    //     }
    // })
    // .catch((err) => {
    //     console.error("There was an issue fetching your data.");
    //     return {
    //         cosmosDB: false,
    //         status: err
    //     }
    // })
    //return response;
    return {
        cosmosDB: false,
        status: CosmosDBStatus.NotConfigured
    }
}

export const frontendSettings = async (): Promise<Response | null> => {
    const response = require("./frontendSettings.json");
    // await fetch("/frontend_settings", {
    //     method: "GET",
    // }).then((res) => {
    //     return res.json()
    // }).catch((err) => {
    //     console.error("There was an issue fetching your data.");
    //     return null
    // })

    return response
}
export const historyMessageFeedback = async (messageId: string, feedback: string): Promise<Response> => {
    const response = await fetch("/history/message_feedback", {
        method: "POST",
        body: JSON.stringify({
            message_id: messageId,
            message_feedback: feedback
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then((res) => {
            return res
        })
        .catch((err) => {
            console.error("There was an issue logging feedback.");
            let errRes: Response = {
                ...new Response,
                ok: false,
                status: 500,
            }
            return errRes;
        })
    return response;
}
