import React, { useState } from "react";
import styled from "styled-components";
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Label } from '@fluentui/react/lib/Label';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { FontIcon, Icon } from '@fluentui/react/lib/Icon';
import { mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';

import styles from "../../../../components/common/Button.module.css";
import { APIConfig, ChatMessage, ChatResponse, ConversationRequest, audioConversationApi, transcriptionApi } from "../../../../api";
import uuid from "react-uuid";

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
};

const StyledHeader = styled.header`
  align-items: center;
  align-self: stretch;
  background-color: transparent;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  flex-flow: row wrap;

  & .buttons {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: right;
    padding: 8px 20px;
    position: relative;
    width: 100%;
  }

  & .icon{
    color: green;
    fontSize: 70px;
    align-items: center;
    align-self: center;
  }

  & .iconerror{
    color: red;
    fontSize: 70px;
    align-items: center;
    align-self: center;
  }

  & .spinner{
    color: #FD6262;
    align-items: center;
    align-self: center;
  }

  & .filename{
    color: #FD6262;
    align-items: center;
    align-self: center;
  }

  & .frame {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    padding: 0px;
    position: relative;
  }

  .buttons-small {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
  }

  & .dropdown{

  }
 
  @media (max-width: 480px) {
    .buttons {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex: 1 100%;
      flex-direction: column;
      gap: 8px;
      justify-content: right;
      padding: 8px 20px;
      position: relative;
      width: 100%;
    }
    .buttons-small {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex: 0 0 auto;
      gap: 8px;
      justify-content: center;
      padding: 8px 20px;
      position: relative;
      width: 100%;
    }
    .frame {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 1;
      flex-grow: 1;
      gap: 10px;
      padding: 0px;
      position: relative;
      font-size: 12px;
      justify-content: center;
    }
  }
  }
`;

type THeaderProps = {
  config: APIConfig,
  unsavedconfig: APIConfig,
  onChange: (value: React.SetStateAction<APIConfig>) => void;
  title: string,
  options: IDropdownOption[],
  onImportClick: () => void;
  onExportClick: () => void;
  onTemplateSelected: (index: string) => void;
};
//FAZER - Tooltip com erro on error
export const Header = ({ config, unsavedconfig, onChange, title, options, onImportClick, onExportClick, onTemplateSelected }: THeaderProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [audioPath, setAudioPath] = useState<string>("");
  const [errorToolTip, setErrorToolTip] = useState<string>();
  const [fileblob, setBlob] = useState<Blob>();
  const [loadedfile, setLoadedFile] = useState<File>();

  const handleTranscribeClick = () => {
    setIsLoading(false);
    setIsSuccess(false);
    setIsError(false);
    transcribeFile();

    // const upload: HTMLInputElement = document.createElement('input');
    // upload.setAttribute('type', 'file');
    // upload.setAttribute('accept', '.mp3,.wav,.ogg,.opus,.webm');
    // upload.onchange = (e) => { handleTranscribeFileChange(e as unknown as React.ChangeEvent<HTMLInputElement>) };
    // upload.click();
  };

  const handleLoadClick = () => {
    setIsLoading(false);
    setIsSuccess(false);
    setIsError(false);
    const upload: HTMLInputElement = document.createElement('input');
    upload.setAttribute('type', 'file');
    upload.setAttribute('accept', '.mp3,.wav,.ogg,.opus,.webm');
    upload.onchange = (e) => { handleLoadFileChange(e as unknown as React.ChangeEvent<HTMLInputElement>) };
    upload.click();
  };

  const transcribeFile = async () => {
    setIsLoading(true);
    const userMessage: ChatMessage = {
      id: uuid(),
      role: "user",
      type: "audio",
      content: "",
      blob: fileblob,
      date: new Date().toISOString(),
    };

    const request: ConversationRequest = {
      messages: [userMessage]
    };

    let result = {} as ChatResponse;
    try {
      if(loadedfile)
      {const response = await transcriptionApi(request, loadedfile.name, config);
      const payload = await response.json();
      const strpayload = (payload.conversation as Array<string>).join("\n");

      console.log(strpayload);
      onChange({ ...unsavedconfig, customerdata: TranscriptionToConversation(payload.conversation as Array<string>), rawcustomerdata: strpayload });
      setIsLoading(false);
      setIsSuccess(true);}
      else throw "File not loaded.";
    }
    catch (e) {
      let errorMessage = "An error occurred. Please try again. If the problem persists, please contact the site administrator. " + e;
      if (result.error?.message) {
        errorMessage = result.error.message;
      }
      else if (typeof result.error === "string") {
        errorMessage = result.error;
      }
      setErrorToolTip(errorMessage);
      setIsLoading(false);
      setIsError(true);
    }
  }

  const handleLoadFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const file = event.target.files?.[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = async (event) => {
        setIsLoading(true);
        const contents = event.target?.result as ArrayBuffer;
        const blob = new Blob([contents]);
        const url = URL.createObjectURL(blob);
        setAudioPath(url);
        setBlob(blob);
        setLoadedFile(file);
        setIsLoading(false);
      };
      fileReader.readAsArrayBuffer(file);
    }
  };

  const TranscriptionToConversation = (transcription: Array<string>): string => {

    var agentid: string;
    var conversation: string = "";
    const firstisagent = unsavedconfig.firstisagent;

    if (firstisagent) agentid = transcription[0].substring(transcription[0].indexOf("SpeakerID=") + 10);
    else agentid = transcription[1].substring(transcription[1].indexOf("SpeakerID=") + 10);

    transcription.forEach((element, index) => {
      const text = transcription[index].substring(transcription[index].indexOf("Text=") + 5, (transcription[index].indexOf("SpeakerID=")));
      const role = transcription[index].substring(transcription[index].indexOf("SpeakerID=") + 10) == agentid ? "Agent" : "Customer";

      conversation += role + ": " + text + "\n";
    });
    return conversation;
  }

  return (
    <StyledHeader>
      <div className="buttons">
        <Label styles={{ root: { fontSize: '36', fontWeight: 'bold' } }} className='frame'>{title}</Label>
        <Dropdown
          placeholder="Select a Template"
          options={options}
          styles={dropdownStyles}
          onChange={(ev, option, index) => onTemplateSelected(option?.key as string)}
        >
        </Dropdown>
        <div className="buttons-small">
          <PrimaryButton
            className={styles.shareButtonRoot}
            text="Import"
            onClick={onImportClick}
          />
          <PrimaryButton
            className={styles.shareButtonRoot}
            onClick={onExportClick}
            text="Export"
          />
        </div>
      </div>
      <div className="buttons">
        <audio controls src={audioPath} autoPlay />
        {isSuccess && (<Icon iconName="CompletedSolid" className='icon' />)}
        {isError && (<Icon title={errorToolTip} iconName="StatusErrorFull" className='iconerror' />)}
        {isLoading && (<Spinner size={SpinnerSize.small} className="spinner" />)}
        <div className="buttons-small">
          <PrimaryButton
            className={styles.shareButtonRoot}
            text="Load"
            onClick={handleLoadClick}
          />
          <PrimaryButton
            className={styles.shareButtonRoot}
            text="Transcribe"
            onClick={handleTranscribeClick}
            disabled={audioPath == ""}
          />
        </div>
      </div>
    </StyledHeader>
  );
};