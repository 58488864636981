import React, { FormEvent } from "react";
import { Separator } from '@fluentui/react/lib/Separator';
import { TextField, MaskedTextField } from '@fluentui/react/lib/TextField';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Checkbox } from '@fluentui/react';
import styled from "styled-components";
import { APIConfig } from "../../../../api";

const StyledConnection = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;

  & .horizontal-frame-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 0px 20px;
    position: relative;
    width: 100%;
  }

  & .field-8 {
    align-self: stretch !important;
    flex: 1 !important;
    flex-grow: 1 !important;
    width: unset !important;
    align-items: flex-end;
  }
  
  @media (max-width: 480px) {
    .horizontal-frame-2 {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex: 1 100%;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      padding: 8px 20px;
      position: relative;
      width: 100%;
  }
`;

const options: IDropdownOption[] = [
  { key: 'text', text: 'Text' },
  { key: 'voice', text: 'Voice' },
  { key: 'asreceived', text: 'Same as received' },
];

const aspects: IDropdownOption[] = [
  { key: 'issue', text: 'Issue' },
  { key: 'resolution', text: 'Resolution' },
  { key: 'chapterTitle', text: 'Title' },
  { key: 'narrative', text: 'Narrative' },
  { key: 'recap', text: 'Recap' },
];

type TInputOutputProps = {
  config: APIConfig,
  unsavedconfig: APIConfig,
  onChange: (value: React.SetStateAction<APIConfig>) => void;
};

export const InputOutput = ({ config, unsavedconfig, onChange }: TInputOutputProps): JSX.Element => {
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>(config.aspects.split(","));

  const onAspectsChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption<any> | undefined, index: number | undefined): void => {
    if (option) {
      var templist = selectedKeys;
      if (option.selected) { templist.push(option.key as string)}
      else { templist = templist.filter(key => key !== option.key) }
      setSelectedKeys(templist);
      onChange({ ...unsavedconfig, aspects: templist.join(",") })
    }

  };

  return (
    <StyledConnection>
      <Separator alignContent="start">Input and Output Configuration</Separator>
      <div className="horizontal-frame-2">
        <Dropdown label="Reply Mode" placeholder="Select Reply Mode" options={options} className="field-8"
          defaultSelectedKey={config.replymode}
          onChange={(e, option, index) => { onChange({ ...unsavedconfig, replymode: option?.key as string }) }}
        />
        <Dropdown label="Summary Aspects" placeholder="Select Aspects" multiSelect options={aspects} className="field-8"
          defaultSelectedKeys={selectedKeys}
          onChange={onAspectsChange}
        />
        <TextField label="Baseline Score" placeholder="Set initial score" className="field-8"
          defaultValue={config.basescore.toString()}
          onChange={(e) => { onChange({ ...unsavedconfig, basescore: e.currentTarget.value }) }} />
        <Checkbox label="First is Agent" defaultChecked={config.firstisagent} className="field-8"
          onChange={(e, checked) => { onChange({ ...unsavedconfig, firstisagent: checked as boolean }) }} />
      </div>
    </StyledConnection>
  );
};